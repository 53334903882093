<template>
  <div class="wrapper flex">
      <Menu />
    <router-view />
  </div>
</template>

<script>
import Menu from "@/components/app/menu";
export default {
  name: "mainLayout",
  components: {
    Menu,
  },
};
</script>
<template>
    <div class="menu">
            <div class="logo">
                <a href="/"></a>
            </div>
            <ul>
                <router-link :to="{ path: '/orders' }" custom v-slot="{ href, isActive, isExactActive }">
                    <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                        <a :href="href">Заказы</a>
                    </li>
                </router-link>
                <router-link :to="{ path: '/products' }" custom v-slot="{ href, isActive, isExactActive }">
                    <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                        <a :href="href">Товары</a>
                    </li>
                </router-link>
                <router-link :to="{ path: '/sections' }" custom v-slot="{ href, isActive, isExactActive }">
                    <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                        <a :href="href">Категории</a>
                    </li>
                </router-link>
                <router-link :to="{ path: '/filters' }" custom v-slot="{ href, isActive, isExactActive }">
                    <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                        <a :href="href">Фильтры</a>
                    </li>
                </router-link>
                <!-- <router-link :to="{ path: '/banners' }" custom v-slot="{ href, isActive, isExactActive }">
                    <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                        <a :href="href">Баннеры</a>
                    </li>
                </router-link> -->
                <!-- <router-link :to="{ path: '/salers' }" custom v-slot="{ href, isActive, isExactActive }">
                    <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                        <a :href="href">Наценки</a>
                    </li>
                </router-link> -->
                <router-link :to="{ path: '/admins' }" custom v-slot="{ href, isActive, isExactActive }">
                    <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                        <a :href="href">Администраторы</a>
                    </li>
                </router-link>
            </ul>
           
        </div>
        
</template>
<style lang="scss" scoped>
    .wrapper .menu ul li {
        &.router-link-active {
            background: linear-gradient(180deg,#001a5c 7.21%,#1c0097 100%);
        }
    }
    .dns {
        width: 90%;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #ff6b00;
        margin: 25px auto;
        span {
            font-size: 14px;
            color: #ff6b00;
            font-weight: 700;
        }
        input {
            border: 1px solid gray;
            padding: 7px;
            display: block;
            outline: none;
            font-size: 16px;
            width: 70%;
            margin: 10px 0;
            color: #484848;
        }
        strong {
            a {
                font-size: 11px;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
</style>
<script>
//import api from "@/components/app/api";

export default {
  name: "Home",
  data() {
    return {
        dnsPrice: 0,
        simaPrice: 0,
        vlarnikaPrice:0,
        simavlarnikaPrice:0
    };
  },
  watch: {},
  computed: {},
  methods: {
    copy() {
        navigator.clipboard.writeText(this.vlarnikaPrice)
    },
    copysima() {
        navigator.clipboard.writeText(this.simavlarnikaPrice)
    },
    dns() {
        let summ = Number(this.dnsPrice)
        if(summ==0) {return}

        if(summ>0 && summ<=1500) {
            console.log('16%');
            this.vlarnikaPrice = Math.ceil(summ + (summ * 0.16))
        }

        if(summ>1500 && summ<=10000) {
            console.log('15%');
            this.vlarnikaPrice = Math.ceil(summ + (summ * 0.15))
        }

        if(summ>10000 && summ<=20000) {
            console.log('14%');
            this.vlarnikaPrice = Math.ceil(summ + (summ * 0.14))
        }
        if(summ>20000 && summ<=40000) {
            console.log('13%');
            this.vlarnikaPrice = Math.ceil(summ + (summ * 0.13))
        }

        if(summ>40000 && summ<=60000) {
            console.log('10%');
            this.vlarnikaPrice = Math.ceil(summ + (summ * 0.10))
        }

        if(summ>60000) {
            console.log('11%');
            this.vlarnikaPrice = Math.ceil(summ + (summ * 0.11))
        }
    },
    sima() {
        let summ = Number(this.simaPrice)
        if(summ==0) {return}

        if(summ>0 && summ<=1500) {
            console.log('13%');
            this.simavlarnikaPrice = Math.ceil(summ + (summ * 0.16))
        }
        if(summ>1500) {
            console.log('8%');
            this.simavlarnikaPrice = Math.ceil(summ + (summ * 0.11))
        }
      
       
    }
  },
  async mounted() {

  },
  async created() {},
  components: {},
};
</script>
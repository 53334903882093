import { createRouter, createWebHistory } from 'vue-router'
import login from '../views/login.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      layout: 'login'
    },
    component: login
  },
  {
    path: '/products',
    name: 'products',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/products.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/orders.vue')
  },
  {
    path: '/orders/:id',
    name: 'ordersPage',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/orders.vue')
  },
  {
    path: '/products/:id',
    name: 'productsPage',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/products.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/order.vue')
  },
  {
    path: '/order/:id',
    name: 'orderPage',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/order.vue')
  },
  {
    path: '/salers',
    name: 'salers',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/salers.vue')
  },
  {
    path: '/banners',
    name: 'banners',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/banners.vue')
  },
  {
    path: '/sections',
    name: 'sections',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/sections.vue')
  },
  {
    path: '/filters',
    name: 'filters',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/filters.vue')
  },
  {
    path: '/admins',
    name: 'admins',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/admins.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


<script>
import axios from "axios";
import helper from "@/components/app/helper";
axios.defaults.headers.common["Content-Language"] =
  helper.methods.getCookie("token");
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


var linkApi = "https://admin.tehnoland.shop/api/api.php"
if(!document.URL.includes('localhost')) {
  linkApi = "/api/api.php"
} 

export default {

  methods: {
    async ORDER_returnMoney(obj) {
    let response = await axios.post("https://admin.tehnoland.shop/ajax/returnMoney/", {
        obj
      });
      return response.data;
    },
    async protect() {
      let response = await axios.post("/api/getData.php", {
        method: "protect",
      });
      return response.data.status;
    },
    async submitFile(obj) {
      axios
        .post(
          linkApi,
          {
            method: "submitFile",
            obj,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function () {
          console.log("SUCCESS!!");
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    async aut(obj) {
      //Авторизация администратора
      console.log(this);
      let response = await axios.post(
        linkApi,
        {
          method: "aut",
          obj,
        }
      );
      return response.data.data;
    },
    async ORDER_add(obj) {
      //Добавляем / редактируем заказ
      let response = await axios.post(
        linkApi,
        {
          method: "ORDER_add",
          obj,
        }
      );
      return response.data.data;
    },
    async ORDER_get(obj) {
      //Получаем заказ
      let response = await axios.post(
        linkApi,
        {
          method: "ORDER_get",
          obj,
        }
      );
      return response.data.data;
    },  
    async ORDER_delete(obj) {
      //Удаляем заказ
      let response = await axios.post(
        linkApi,
        {
          method: "ORDER_del",
          obj,
        }
      );
      return response.data.data;
    },    
    async SECTIONS_search(obj) {
      //Поиск категории
      let response = await axios.post(
        linkApi,
        {
          method: "SECTIONS_search",
          obj,
        }
      );
      return response.data.data;
    },
    async USERS_searchUser(obj) {
      //Поиск юзера
      let response = await axios.post(
      linkApi,
        {
          method: "USERS_searchUser",
          obj,
        }
      );
      return response.data.data;
    },
    async PRODUCT_search(obj) {
      //Поиск товара по артикулу
      let response = await axios.post(
        linkApi,
        {
          method: "PRODUCT_search",
          obj,
        }
      );
      return response.data.data;
    },
    async FILTERS_list(obj) {
      //Получаем список фильтров
      let response = await axios.post(
        linkApi,
        {
          method: "FILTERS_list",
          obj,
        }
      );
      return response.data.data;
    },
    async BANNERS_del(obj) {
      //Удаляем банер
      let response = await axios.post(
        linkApi,
        {
          method: "BANNERS_del",
          obj,
        }
      );
      return response.data.data;
    },
    async ORDER_setStatus(obj) {
      //Устанавливаем статус заказа
      let response = await axios.post(
        linkApi,
        {
          method: "ORDER_setStatus",
          obj
        }
      );
      return response.data.data;
    },
    async ORDER_save(obj) {
      //Страница с заказами
      let response = await axios.post(
        linkApi,
        {
          method: "ORDER_save",
          obj
        }
      );
      return response.data;
    },
    async PRODUCT_save(obj) {
      //Страница с заказами
      let response = await axios.post(
        linkApi,
        {
          method: "PRODUCT_save",
          obj
        }
      );
      return response.data;
    },
    async PRODUCT_list(obj) {
      //Страница с товарами
      let response = await axios.post(
        linkApi,
        {
          method: "PRODUCT_list",
          obj
        }
      );
      return response.data;
    },
    async ORDER_list(obj) {
      //Страница с заказами
      let response = await axios.post(
        linkApi,
        {
          method: "ORDER_list",
          obj
        }
      );
      return response.data;
    },
    async SALERS_list() {
      //Получаем список поставщиков
      let response = await axios.post(
        linkApi,
        {
          method: "SALERS_list",
        }
      );
      return response.data.data;
    },
    async ADMINS_getList() {
      //Получаем список администраторов
      let response = await axios.post(
        linkApi,
        {
          method: "ADMINS_getList",
        }
      );
      return response.data.data;
    },
    async BANNERS_list() {
      //Получаем список администраторов
      let response = await axios.post(
        linkApi,
        {
          method: "BANNERS_list",
        }
      );
      return response.data.data;
    },
    async FILTERS_addDetails(obj) {
      // добавляем или редактируем значение фильтра
      let response = await axios.post(
        linkApi,
        {
          method: "FILTERS_addDetails",
          obj,
        }
      );
      return response.data.data;
    },
    async ADMINS_add(obj) {
      // добавляем или редактируем администратора
      let response = await axios.post(
        linkApi,
        {
          method: "ADMINS_add",
          obj,
        }
      );
      return response.data.data;
    },
    async BANNERS_add(obj) {
      // добавляем банер
      let response = await axios.post(
        linkApi,
        {
          method: "BANNERS_add",
          obj,
        }
      );
      return response.data.data;
    },
    async SALER_add(obj) {
      // добавляем или редактируем наценки по поставщику
      let response = await axios.post(
        linkApi,
        {
          method: "SALER_add",
          obj,
        }
      );
      return response.data.data;
    },
    async FILTERS_add(obj) {
      // добавляем или редактируем фильтр
      let response = await axios.post(
        linkApi,
        {
          method: "FILTERS_add",
          obj,
        }
      );
      return response.data.data;
    },
    async SECTIONS_get(obj) {
      //Получаем инфо про категорию
      let response = await axios.post(
        linkApi,
        {
          method: "SECTIONS_get",
          obj,
        }
      );
      return response.data.data;
    },
    async FILTERS_del(obj) {
      // удаляем фильтр
      let response = await axios.post(
        linkApi,
        {
          method: "FILTERS_del",
          obj,
        }
      );
      return response.data.data;
    },
    async FILTERS_delDetails(obj) {
      // удаляем значение фильтра
      let response = await axios.post(
        linkApi,
        {
          method: "FILTERS_delDetails",
          obj,
        }
      );
      return response.data.data;
    },
    async SECTIONS_add(obj) {
      // добавляем или редактируем категорию
      let response = await axios.post(
        linkApi,
        {
          method: "SECTIONS_add",
          obj,
        }
      );
      return response.data.data;
    },
    async ADMINS_del(obj) {
      // добавляем или редактируем администратора
      let response = await axios.post(
        linkApi,
        {
          method: "ADMINS_del",
          obj,
        }
      );
      return response.data.data;
    },
    async SAILERS_list() {
      //Получаем список поставщиков
      let response = await axios.post(
        linkApi,
        {
          method: "SAILERS_list",
        }
      );
      return response.data.data;
    },
  },
};
</script>
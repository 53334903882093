<style lang="scss" >
.warning {
  position: fixed;
  left: 0;
  right: 0;
  font-size: 18px;
  top: 0;
  text-align: center;
  padding: 20px;
  font-weight: 500;
  border-bottom: 1px solid #0dbf1b;
  background: rgba(207, 255, 226, 0.9);
  transition: 0.3s ease-out;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-name: slidein;
  &.error {
    background: rgba(255, 207, 207, 0.9);
    border-bottom: 1px solid #ff5b5b;
  }
  @keyframes slidein {
    from {
      top: 0;
    }

    to {
      top: -150px;
    }
  }
}
</style>
<script>
export default {
  name: "Helper",
  methods: {
    parsePhoenix(e, val) {
      let okKey = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Delete",
        "-",
        "(",
        ")",
      ];

      if (
        !okKey.includes(e.key) &&
        e.key != "Shift" &&
        e.key != "Backspace" &&
        e.key != "ArrowLeft" &&
        e.key != "ArrowRight" &&
        e.key != " "
      ) {
        val = val.substring(0, val.length - 1);
      }
      if (val.length > 8) {
        if (e.key != "Backspace" && e.key != "Delete") {
          val = val.trim();
          if (val.charAt(0) == "3" && val.charAt(1) == "8") {
            val = val.slice(1);
            val = val.slice(1);
          }

          val = val.replaceAll(") ", "/@/");
          val = val.replaceAll(" ", "");
          val = val.replaceAll("/@/", ") ");
          val = val.replaceAll("-", "");

          if (val.charAt(0) != "(") {
            val = "(" + val;
          }
          if (val.charAt(4) != ")") {
            val = val.slice(0, 4) + ") " + val.slice(4);
          }

          val = val.slice(0, 9) + " " + val.slice(9);
          val = val.slice(0, 12) + " " + val.slice(12);
        }
      }
      if (val.length > 15) {
        val = val.substring(0, val.length - 1);
      }
      
      return val;
    },
    checkPhoenix(val) {
        if(val.substr(0, 5)=='(959)') {
          var phonenolug =/^\(?([959]{3})\)?[ ]?([0-9]{3})[ ]?([0-9]{2})?[ ]?([0-9]{2})$/;
          if (!val.match(phonenolug) || !val.startsWith("(959) ")) {
            return false
          } else {
            return true
          }
        }

        if(val.substr(0, 5)=='(990)') {
          var phonenomel =/^\(?([990]{3})\)?[ ]?([0-9]{3})[ ]?([0-9]{2})?[ ]?([0-9]{2})$/;
          if (!val.match(phonenomel) || !val.startsWith("(990) ")) {
            return false
          } else {
            return true
          }
        }

        var phoneno =/^\(?([949]{3})\)?[ ]?([0-9]{3})[ ]?([0-9]{2})?[ ]?([0-9]{2})$/;
        if (!val.match(phoneno) || !val.startsWith("(949) ")) {
          return false
        } else {
          return true
        }
    },
    number_format(number, decimals, dec_point, thousands_sep) {
      // http://kevin.vanzonneveld.net
      // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
      // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
      // +     bugfix by: Michael White (http://getsprink.com)
      // +     bugfix by: Benjamin Lupton
      // +     bugfix by: Allan Jensen (http://www.winternet.no)
      // +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
      // +     bugfix by: Howard Yeend
      // +    revised by: Luke Smith (http://lucassmith.name)
      // +     bugfix by: Diogo Resende
      // +     bugfix by: Rival
      // +      input by: Kheang Hok Chin (http://www.distantia.ca/)
      // +   improved by: davook
      // +   improved by: Brett Zamir (http://brett-zamir.me)
      // +      input by: Jay Klehr
      // +   improved by: Brett Zamir (http://brett-zamir.me)
      // +      input by: Amir Habibi (http://www.residence-mixte.com/)
      // +     bugfix by: Brett Zamir (http://brett-zamir.me)
      // +   improved by: Theriault
      // +   improved by: Drew Noakes
      // *     example 1: number_format(1234.56);
      // *     returns 1: '1,235'
      // *     example 2: number_format(1234.56, 2, ',', ' ');
      // *     returns 2: '1 234,56'
      // *     example 3: number_format(1234.5678, 2, '.', '');
      // *     returns 3: '1234.57'
      // *     example 4: number_format(67, 2, ',', '.');
      // *     returns 4: '67,00'
      // *     example 5: number_format(1000);
      // *     returns 5: '1,000'
      // *     example 6: number_format(67.311, 2);
      // *     returns 6: '67.31'
      // *     example 7: number_format(1000.55, 1);
      // *     returns 7: '1,000.6'
      // *     example 8: number_format(67000, 5, ',', '.');
      // *     returns 8: '67.000,00000'
      // *     example 9: number_format(0.9, 0);
      // *     returns 9: '1'
      // *    example 10: number_format('1.20', 2);
      // *    returns 10: '1.20'
      // *    example 11: number_format('1.20', 4);
      // *    returns 11: '1.2000'
      // *    example 12: number_format('1.2000', 3);
      // *    returns 12: '1.200'
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        toFixedFix = function (n, prec) {
          // Fix for IE parseFloat(0.55).toFixed(0) = 0;
          var k = Math.pow(10, prec);
          return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
    urlGenerator(str) {
      str = str.toLowerCase(); // все в нижний регистр
      var cyr2latChars = new Array(
        ["а", "a"],
        ["б", "b"],
        ["в", "v"],
        ["г", "g"],
        ["д", "d"],
        ["е", "e"],
        ["ё", "yo"],
        ["ж", "zh"],
        ["з", "z"],
        ["и", "i"],
        ["й", "y"],
        ["к", "k"],
        ["л", "l"],
        ["м", "m"],
        ["н", "n"],
        ["о", "o"],
        ["п", "p"],
        ["р", "r"],
        ["с", "s"],
        ["т", "t"],
        ["у", "u"],
        ["ф", "f"],
        ["х", "h"],
        ["ц", "c"],
        ["ч", "ch"],
        ["ш", "sh"],
        ["щ", "shch"],
        ["ъ", ""],
        ["ы", "y"],
        ["ь", ""],
        ["э", "e"],
        ["ю", "yu"],
        ["я", "ya"],

        ["А", "A"],
        ["Б", "B"],
        ["В", "V"],
        ["Г", "G"],
        ["Д", "D"],
        ["Е", "E"],
        ["Ё", "YO"],
        ["Ж", "ZH"],
        ["З", "Z"],
        ["И", "I"],
        ["Й", "Y"],
        ["К", "K"],
        ["Л", "L"],
        ["М", "M"],
        ["Н", "N"],
        ["О", "O"],
        ["П", "P"],
        ["Р", "R"],
        ["С", "S"],
        ["Т", "T"],
        ["У", "U"],
        ["Ф", "F"],
        ["Х", "H"],
        ["Ц", "C"],
        ["Ч", "CH"],
        ["Ш", "SH"],
        ["Щ", "SHCH"],
        ["Ъ", ""],
        ["Ы", "Y"],
        ["Ь", ""],
        ["Э", "E"],
        ["Ю", "YU"],
        ["Я", "YA"],

        ["a", "a"],
        ["b", "b"],
        ["c", "c"],
        ["d", "d"],
        ["e", "e"],
        ["f", "f"],
        ["g", "g"],
        ["h", "h"],
        ["i", "i"],
        ["j", "j"],
        ["k", "k"],
        ["l", "l"],
        ["m", "m"],
        ["n", "n"],
        ["o", "o"],
        ["p", "p"],
        ["q", "q"],
        ["r", "r"],
        ["s", "s"],
        ["t", "t"],
        ["u", "u"],
        ["v", "v"],
        ["w", "w"],
        ["x", "x"],
        ["y", "y"],
        ["z", "z"],

        ["A", "A"],
        ["B", "B"],
        ["C", "C"],
        ["D", "D"],
        ["E", "E"],
        ["F", "F"],
        ["G", "G"],
        ["H", "H"],
        ["I", "I"],
        ["J", "J"],
        ["K", "K"],
        ["L", "L"],
        ["M", "M"],
        ["N", "N"],
        ["O", "O"],
        ["P", "P"],
        ["Q", "Q"],
        ["R", "R"],
        ["S", "S"],
        ["T", "T"],
        ["U", "U"],
        ["V", "V"],
        ["W", "W"],
        ["X", "X"],
        ["Y", "Y"],
        ["Z", "Z"],

        [" ", "_"],
        ["0", "0"],
        ["1", "1"],
        ["2", "2"],
        ["3", "3"],
        ["4", "4"],
        ["5", "5"],
        ["6", "6"],
        ["7", "7"],
        ["8", "8"],
        ["9", "9"],
        ["-", "-"],
        ["+", "-plus-"]
      );

      var newStr = new String();

      for (var i = 0; i < str.length; i++) {
        let ch = str.charAt(i);
        var newCh = "";

        for (var j = 0; j < cyr2latChars.length; j++) {
          if (ch == cyr2latChars[j][0]) {
            newCh = cyr2latChars[j][1];
          }
        }
        // Если найдено совпадение, то добавляется соответствие, если нет - пустая строка
        newStr += newCh;
      }
      // Удаляем повторяющие знаки - Именно на них заменяются пробелы.
      // Так же удаляем символы перевода строки, но это наверное уже лишнее
      return newStr.replace(/[_]{2,}/gim, "_").replace(/\n/gim, "");
    },
    isEmptyStr(str) {
      // Проверяем строку на пустоту
      if (str.trim().length == 0) {
        return true;
      } else {
        return false;
      }
    },
    isObject(obj) {
      // Проверяем на обьект
      var type = typeof obj;
      return type === "function" || (type === "object" && !!obj);
    },
    warning(mess, type) {
      let newDiv = document.createElement("div");
      newDiv.innerHTML = mess;
      newDiv.classList.add("warning");
      if (type == "error") {
        newDiv.classList.add("error");
      }
      document.body.append(newDiv);
      setTimeout(function () {
        newDiv.remove();
      }, 3000);
    },
    getCookie(name) {
      let matches = document.cookie.match(
        // eslint-disable-next-line
        new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    setCookie(name, value, options = { secure: true, "max-age": 9870400 }) {
      options = {
        path: "/",
        // при необходимости добавьте другие значения по умолчанию
        ...options,
      };

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
      }

      let updatedCookie =
        encodeURIComponent(name) + "=" + encodeURIComponent(value);

      for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
          updatedCookie += "=" + optionValue;
        }
      }

      document.cookie = updatedCookie;
    },
  },
};
</script>
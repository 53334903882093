<template>
    <component :is="layout"> 
      <router-view />
    </component>
</template>

<script>
import loginLayout from '@/layouts/loginLayout'
import mainLayout from '@/layouts/mainLayout'

export default {
  computed: {
    layout() {
      console.log(this.$route.meta.layout)
      return (this.$route.meta.layout || 'empty')+'Layout'
    }
  },
  components: {
    loginLayout, mainLayout
  },
  methods: {
        
  },
  data() {
    return {
      
    }
  }
}
</script>


<style lang="scss">
  //@import 'assets/style.css';
</style>


<template>
  <form action="" class="login flex">
    <div class="logo">
      <img src="img/logo.svg" alt="BusTravel" />
      <span>Панель управления</span>
    </div>
    <div class="aut">
      <div class="row">
        <span>Логин</span>
        <input type="text" v-model="autLogin" @keyup.enter="adminAut" />
      </div>
      <div class="row">
        <span>Пароль</span>
        <input type="text" v-model="autPass" @keyup.enter="adminAut" />
      </div>
      <div class="row">
        <button type="button" v-show="loginBtnIsView" @click="adminAut">
          Вход
        </button>
        <span v-show="!loginBtnIsView">Идет авторизация</span>
      </div>
    </div>
  </form>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&display=swap");
.login {
  width: 500px;
  font-family: Montserrat;
  font-weight: 700;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  background: #f9f9f9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
  .logo {
    width: 45%;
    img {
      width: 175px;
      display: block;
      margin: 0 auto;
    }
    span {
      display: block;
      color: rgb(30, 4, 179);
      padding: 15px 0;
      font-size: 13px;
      padding-top: 35px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .aut {
    width: 55%;
    padding: 0 0 0 35px;
    color: #454545;
    .row {
      padding: 10px 0;
      span {
        display: block;
        font-size: 12px;
        margin-bottom: 3px;
      }
      input {
        padding: 7px 10px;
        border: 1px solid rgb(219, 219, 219);
        font-family: Montserrat;
        font-weight: 500;
        color: #454545;
        outline: none;
        border-radius: 3px;
        width: 100%;
        box-sizing: border-box;
      }
      button {
        outline: none;
        border: none;
        background: linear-gradient(180deg, #ff0000 0%, #ac0000 100%);
        color: white;
        padding: 10px 20px 10px 20px;
        font-size: 14px;
        border-radius: 5px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<script>
import api from "@/components/app/api";
import helper from "@/components/app/helper";
export default {
  name: "Home",
  data() {
    return {
      autLogin: "",
      autPass: "",
      loginBtnIsView: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    async adminAut() {
      if (helper.methods.isEmptyStr(this.autLogin)) {
        helper.methods.warning("Поле логин не должно быть пустым", "error");
        return;
      }
      if (helper.methods.isEmptyStr(this.autPass)) {
        helper.methods.warning("Поле пароль не должно быть пустым", "error");
        return;
      }

      let response = await api.methods.aut({
        login: this.autLogin,
        pass: this.autPass,
      });
      if (response.token != "") {
        helper.methods.setCookie("token", response.token);
        helper.methods.setCookie("name", response.name);
        helper.methods.setCookie("login", response.login);
        helper.methods.setCookie("id", response.id);
        helper.methods.warning(`Добро пожаловать, ${response.name}`, "ok");
        setTimeout(async () => {
         // this.$router.push("/admins");
          window.document.location = "/orders"
        }, 3000);
      } else {
        helper.methods.warning(`Не верные логин или пароль!!!`, "error");
      }
    },
  },
  created() {},
  async mounted() {
    if(helper.methods.getCookie("token")) {
      window.document.location = "/orders"
    }
  },
  components: {},
};
</script>